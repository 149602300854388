/* website: 3919-kenknappford-190
 * created at 2022-12-22 10:37 by slachkar
 */

// Import all makes and organization styles files
@import "../templates/makes/ford.scss";
@import "../utils/icons.scss";

.widget-sr {
  &.dealer__kenknappford-190 {
      .sr-modal--white-close{
        color: #ffffff !important;
        text-shadow: 0px 0px 2px #000000;
      }
  }
}